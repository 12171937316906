import * as yup from 'yup'
import validator from 'validator'
import { HSLA_REGEXP, HSL_REGEXP, RGBA_REGEXP } from 'util/colors'
import { EMAIL_CHANNEL_TYPES } from 'ducks/channels/channelTypes'
import {
  EMAIL_SERVER_DOMAIN,
  EMAIL_SERVER_DOMAIN_PORT,
  EMAIL_SERVER_IS_UPDATE_PASSWORD,
  EMAIL_SERVER_LOGIN_EMAIL,
  EMAIL_SERVER_LOGIN_PASSWORD,
  IMAP_SERVER,
  SMTP_SERVER,
} from 'ducks/mailboxes/constants'

export const getImapServerFieldName = name => `${IMAP_SERVER}.${name}`
export const getSmtpServerFieldName = name => `${SMTP_SERVER}.${name}`

const COLOR_REGEXP = new RegExp(
  [RGBA_REGEXP.source, HSL_REGEXP.source, HSLA_REGEXP.source].join('|'),
  'i'
)

// NOTE (jscheel): Mimics what is in Colorable concern on backend
export const DEFAULT_COLORS = [
  'hsl(45, 76%, 57%)',
  'hsl(187, 69%, 53%)',
  'rgba(125, 255, 45, 0.7)',
  'rgba(145, 30, 180, 0.7)',
  'rgba(245, 130, 48, 0.7)',
  'rgba(45, 253, 190, 0.7)',
  'rgba(52, 130, 234, 0.7)',
  'rgba(210, 245, 60, 0.9)',
  'rgba(240, 50, 230, 0.7)',
  'rgba(70, 240, 240, 0.7)',
  'rgba(250, 190, 190, 0.7)',
  'rgba(0, 128, 128, 0.7)',
  'rgba(230, 190, 255, 0.7)',
  'rgba(170, 110, 40, 0.7)',
  'rgba(255, 250, 200, 0.7)',
  'rgba(128, 0, 0, 0.7)',
  'rgba(170, 255, 195, 0.7)',
  'rgba(128, 128, 0, 0.7)',
  'rgba(255, 215, 180, 0.7)',
  'rgba(0, 0, 128, 0.7)',
  'rgba(128, 128, 128, 0.7)',
  'rgba(0, 0, 0, 0.7)',
  'rgba(60, 180, 75, 0.7)',
  'rgba(255, 225, 25, 0.7)',
]

const getAuthCommonConfig = channelType => ({
  schema: yup.object().shape({
    id: yup.string().nullable(),
    name: yup.string().required(`${app.t('Mailbox')} name is required`),
    sender_name: yup
      .string()
      .trim()
      .nullable(),
    color: yup
      .string()
      .trim()
      .matches(COLOR_REGEXP, 'Invalid color')
      .required(`${app.t('Mailbox')} color is required`),
  }),
  showEmail: false,
  formEmptyState: {
    id: null,
    name: '',
    sender_name: '',
    color: 'rgba(89,208,21,1)',
    channel_type: channelType,
  },
})

const getServerEmailSchema = (serverId, isSmtp) => {
  const schema = {
    [EMAIL_SERVER_DOMAIN]: yup
      .string()
      .required('Domain is required')
      .test({
        test: value => validator.isFQDN(value || ''),
        message: 'Domain is invalid',
      }),
  }
  if (isSmtp) {
    schema[EMAIL_SERVER_DOMAIN_PORT] = yup
      .number()
      .typeError('Port must be a number')
      .required('Port is required')
      .min(1, 'Must be greater than 0')
  } else {
    schema[EMAIL_SERVER_LOGIN_EMAIL] = yup
      .string()
      .required('Username is required')
    schema[EMAIL_SERVER_LOGIN_PASSWORD] = serverId
      ? yup
          .string()
          .nullable()
          .when(EMAIL_SERVER_IS_UPDATE_PASSWORD, {
            is: true,
            then: yup.string().required('Password is required'),
          })
      : yup.string().required('Password is required')
    schema[EMAIL_SERVER_DOMAIN_PORT] = yup
      .number()
      .typeError('Port must be a number')
      .required('Port is required')
      .min(1, 'Must be greater than 0')
      .max(65536, 'Must be less than 65536')
  }

  return yup.object().shape(schema)
}

const getConfig = ({ imapServer, smtpServer } = {}) => ({
  google: getAuthCommonConfig(EMAIL_CHANNEL_TYPES.google),
  outlook: getAuthCommonConfig(EMAIL_CHANNEL_TYPES.outlook),
  office365: getAuthCommonConfig(EMAIL_CHANNEL_TYPES.office365),
  imap: {
    schema: yup.object().shape({
      id: yup.string().nullable(),
      email: yup
        .string()
        .email(`${app.t('Mailbox')} email is invalid`)
        .required(`${app.t('Mailbox')} email is required`),
      name: yup.string().required(`${app.t('Mailbox')} name is required`),
      sender_name: yup
        .string()
        .trim()
        .nullable(),
      color: yup
        .string()
        .trim()
        .matches(COLOR_REGEXP, 'Invalid color')
        .required(`${app.t('Mailbox')} color is required`),
      [IMAP_SERVER]: imapServer
        ? getServerEmailSchema(imapServer?.id)
        : undefined,
      [SMTP_SERVER]: smtpServer
        ? getServerEmailSchema(smtpServer?.id, true)
        : undefined,
    }),
    showEmail: true,
    formEmptyState: {
      id: null,
      email: '',
      name: '',
      sender_name: '',
      color: 'rgba(89,208,21,1)',
      channel_type: EMAIL_CHANNEL_TYPES.imap,
    },
  },
  facebook: {
    schema: yup.object().shape({
      id: yup.string().nullable(),
      name: yup.string().required(`${app.t('Mailbox')} name is required`),
      sender_name: yup
        .string()
        .trim()
        .nullable(),
      color: yup
        .string()
        .trim()
        .matches(COLOR_REGEXP, 'Invalid color')
        .required('Channel color is required'),
    }),
    showEmail: false,
    formEmptyState: {
      id: null,
      name: '',
      sender_name: '',
      color: 'rgba(89,208,21,1)',
    },
  },
  instagram: {
    schema: yup.object().shape({
      id: yup.string().nullable(),
      name: yup.string().required(`${app.t('Mailbox')} name is required`),
      sender_name: yup
        .string()
        .trim()
        .nullable(),
      color: yup
        .string()
        .trim()
        .matches(COLOR_REGEXP, 'Invalid color')
        .required('Channel color is required'),
    }),
    showEmail: false,
    formEmptyState: {
      id: null,
      name: '',
      sender_name: '',
      color: 'rgba(193,53,132,1)',
    },
  },
  forwarding: {
    schema: yup.object().shape({
      id: yup.string().nullable(),
      email: yup
        .string()
        .email(`${app.t('Mailbox')} email is invalid`)
        .required(`${app.t('Mailbox')} email is required`),
      name: yup.string().required(`${app.t('Mailbox')} name is required`),
      sender_name: yup
        .string()
        .trim()
        .nullable(),
      color: yup
        .string()
        .trim()
        .matches(COLOR_REGEXP, 'Invalid color')
        .required(`${app.t('Mailbox')} color is required`),
    }),
    showEmail: true,
    formEmptyState: {
      id: null,
      email: '',
      name: '',
      sender_name: '',
      color: 'rgba(89,208,21,1)',
      channel_type: 'FORWARDING',
    },
  },
  legacy: {
    schema: yup.object().shape({
      id: yup.string().nullable(),
      email: yup
        .string()
        .email(`${app.t('Mailbox')} email is invalid`)
        .required(`${app.t('Mailbox')} email is required`),
      name: yup.string().required(`${app.t('Mailbox')} name is required`),
      sender_name: yup
        .string()
        .trim()
        .nullable(),
      color: yup
        .string()
        .trim()
        .matches(COLOR_REGEXP, 'Invalid color')
        .required(`${app.t('Mailbox')} color is required`),
    }),
    showEmail: true,
    formEmptyState: {
      id: null,
      email: '',
      name: '',
      sender_name: '',
      color: 'rgba(89,208,21,1)',
      channel_type: 'LEGACY',
    },
  },
})

export default getConfig
